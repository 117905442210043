import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  CompetitionEventLookupCode: { input: any; output: any; }
  JSONObject: { input: { [prop: string]: any }; output: { [prop: string]: any }; }
  Timestamp: { input: number; output: number; }
};

export type Actor = Device | User;

export type Athlete = {
  __typename?: 'Athlete';
  category: Category;
  club?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  ijruId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type BatteryStatus = {
  __typename?: 'BatteryStatus';
  automatic: Scalars['Boolean']['output'];
  batteryLevel: Scalars['Int']['output'];
  charging?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type BatteryStatusInput = {
  automatic: Scalars['Boolean']['input'];
  batteryLevel: Scalars['Int']['input'];
  charging?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Category = {
  __typename?: 'Category';
  competitionEventIds: Array<Scalars['CompetitionEventLookupCode']['output']>;
  entries: Array<Entry>;
  entry?: Maybe<Entry>;
  group: Group;
  id: Scalars['ID']['output'];
  judgeAssignments: Array<JudgeAssignment>;
  latestRankedResult?: Maybe<RankedResult>;
  name: Scalars['String']['output'];
  pagePrintConfig: Array<PagePrintConfig>;
  participants: Array<Participant>;
  rankedResult?: Maybe<RankedResult>;
  rankedResults: Array<RankedResult>;
  rulesId: Scalars['String']['output'];
  type: CategoryType;
};


export type CategoryEntriesArgs = {
  competitionEventId?: InputMaybe<Scalars['CompetitionEventLookupCode']['input']>;
};


export type CategoryEntryArgs = {
  entryId: Scalars['ID']['input'];
};


export type CategoryLatestRankedResultArgs = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  maxVisibility?: InputMaybe<ResultVersionType>;
};


export type CategoryRankedResultArgs = {
  resultId: Scalars['ID']['input'];
};


export type CategoryRankedResultsArgs = {
  beforeLockedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  competitionEventId?: InputMaybe<Scalars['CompetitionEventLookupCode']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxVisibility?: InputMaybe<ResultVersionType>;
};

export enum CategoryType {
  Individual = 'Individual',
  Team = 'Team'
}

export type CreateAthleteInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCategoryInput = {
  competitionEventIds?: InputMaybe<Array<Scalars['CompetitionEventLookupCode']['input']>>;
  name: Scalars['String']['input'];
  rulesId: Scalars['String']['input'];
  type: CategoryType;
};

export type CreateEntryInput = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  heat?: InputMaybe<Scalars['Int']['input']>;
  pool?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateGroupInput = {
  name: Scalars['String']['input'];
  resultVisibility?: InputMaybe<ResultVisibilityLevel>;
};

export type CreateJudgeAssignmentInput = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  judgeType: Scalars['String']['input'];
  options?: InputMaybe<Scalars['JSONObject']['input']>;
  pool?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateJudgeInput = {
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateMarkScoresheetInput = {
  options?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type CreateTallyScoresheetInput = {
  options?: InputMaybe<Scalars['JSONObject']['input']>;
  tally?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type CreateTeamInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  members: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Device = {
  __typename?: 'Device';
  battery?: Maybe<BatteryStatus>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  streamShares: Array<DeviceStreamShare>;
};

export type DeviceStreamJudgeInfo = {
  __typename?: 'DeviceStreamJudgeInfo';
  competitionEventId?: Maybe<Scalars['CompetitionEventLookupCode']['output']>;
  judgeType?: Maybe<Scalars['String']['output']>;
  rulesId?: Maybe<Scalars['String']['output']>;
};

export type DeviceStreamJudgeInfoInput = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  judgeType: Scalars['String']['input'];
  rulesId: Scalars['String']['input'];
};

export type DeviceStreamMarkEvent = {
  __typename?: 'DeviceStreamMarkEvent';
  device: Device;
  info: DeviceStreamJudgeInfo;
  mark: Scalars['JSONObject']['output'];
  sequence: Scalars['Int']['output'];
  tally: Scalars['JSONObject']['output'];
};

export type DeviceStreamShare = {
  __typename?: 'DeviceStreamShare';
  device: Device;
  expiresAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  status: DeviceStreamShareStatus;
  user: User;
};

export enum DeviceStreamShareStatus {
  Accepted = 'Accepted',
  Pending = 'Pending'
}

export type Entry = {
  __typename?: 'Entry';
  category: Category;
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  createdAt: Scalars['Timestamp']['output'];
  didNotSkipAt?: Maybe<Scalars['Timestamp']['output']>;
  heat?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lockedAt?: Maybe<Scalars['Timestamp']['output']>;
  participant: Participant;
  pool?: Maybe<Scalars['Int']['output']>;
  scoresheet?: Maybe<Scoresheet>;
  scoresheets: Array<Scoresheet>;
};


export type EntryScoresheetArgs = {
  scoresheetId: Scalars['ID']['input'];
};


export type EntryScoresheetsArgs = {
  since?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type Group = {
  __typename?: 'Group';
  admins: Array<User>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  currentHeat?: Maybe<Scalars['Int']['output']>;
  deviceJudge: Judge;
  entries: Array<Entry>;
  entriesByHeat: Array<Entry>;
  entry?: Maybe<Entry>;
  id: Scalars['ID']['output'];
  judges: Array<Judge>;
  name: Scalars['String']['output'];
  resultVisibility?: Maybe<ResultVisibilityLevel>;
  viewers: Array<User>;
};


export type GroupCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type GroupEntriesByHeatArgs = {
  heat: Scalars['Int']['input'];
};


export type GroupEntryArgs = {
  entryId: Scalars['ID']['input'];
};

export type Judge = {
  __typename?: 'Judge';
  assignments: Array<JudgeAssignment>;
  device?: Maybe<Device>;
  group: Group;
  id: Scalars['ID']['output'];
  ijruId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};


export type JudgeAssignmentsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type JudgeAssignment = {
  __typename?: 'JudgeAssignment';
  category: Category;
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  options?: Maybe<Scalars['JSONObject']['output']>;
  pool?: Maybe<Scalars['Int']['output']>;
};

export type MarkScoresheet = Scoresheet & {
  __typename?: 'MarkScoresheet';
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  device: Device;
  entry: Entry;
  excludedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  marks: Array<Scalars['JSONObject']['output']>;
  openedAt?: Maybe<Array<Scalars['Timestamp']['output']>>;
  options?: Maybe<Scalars['JSONObject']['output']>;
  rulesId: Scalars['String']['output'];
  submittedAt?: Maybe<Scalars['Timestamp']['output']>;
  submitterProgramVersion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addDeviceStreamMark: DeviceStreamMarkEvent;
  addGroupAdmin: Group;
  addGroupViewer: Group;
  addStreamMark: StreamMarkEvent;
  createAthlete: Athlete;
  createCategory: Category;
  createDeviceStreamShare: DeviceStreamShare;
  createEntry: Entry;
  createGroup: Group;
  createJudge: Judge;
  createJudgeAssignment: JudgeAssignment;
  createMarkScoresheet: MarkScoresheet;
  createTallyScoresheet: TallyScoresheet;
  createTeam: Team;
  deleteCategory: Category;
  deleteDeviceStreamShare: DeviceStreamShare;
  deleteJudgeAssignment: JudgeAssignment;
  deleteParticipant: Participant;
  fillMarkScoresheet: MarkScoresheet;
  fillTallyScoresheet: TallyScoresheet;
  registerDevice: Scalars['String']['output'];
  registerUser: Scalars['String']['output'];
  releaseRankedResultVersion: RankedResult;
  removeGroupAdmin: Group;
  removeGroupViewer: Group;
  reorderEntry: Entry;
  requestDeviceStreamShare: DeviceStreamShare;
  setCurrentHeat: Group;
  setJudgeDevice: Judge;
  setPagePrintConfig: Category;
  setRankedResultVersion: RankedResult;
  setScoresheetExclusion: Scoresheet;
  setScoresheetOptions: Scoresheet;
  toggleEntryLock: Entry;
  toggleGroupComplete: Group;
  unsetJudgeDevice: Judge;
  updateAthlete: Athlete;
  updateCategory: Category;
  updateDeviceStatus: Device;
  updateGroup: Group;
  updateJudge: Judge;
  updateJudgeAssignment: JudgeAssignment;
  updateTeam: Team;
  updateUser: User;
};


export type MutationAddDeviceStreamMarkArgs = {
  info?: InputMaybe<DeviceStreamJudgeInfoInput>;
  mark: Scalars['JSONObject']['input'];
  tally: Scalars['JSONObject']['input'];
};


export type MutationAddGroupAdminArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddGroupViewerArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddStreamMarkArgs = {
  mark: Scalars['JSONObject']['input'];
  scoresheetId: Scalars['ID']['input'];
  tally: Scalars['JSONObject']['input'];
};


export type MutationCreateAthleteArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateAthleteInput;
};


export type MutationCreateCategoryArgs = {
  data: CreateCategoryInput;
  groupId: Scalars['ID']['input'];
};


export type MutationCreateDeviceStreamShareArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationCreateEntryArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateEntryInput;
  participantId: Scalars['ID']['input'];
};


export type MutationCreateGroupArgs = {
  data: CreateGroupInput;
};


export type MutationCreateJudgeArgs = {
  data: CreateJudgeInput;
  groupId: Scalars['ID']['input'];
};


export type MutationCreateJudgeAssignmentArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateJudgeAssignmentInput;
  judgeId: Scalars['ID']['input'];
};


export type MutationCreateMarkScoresheetArgs = {
  data: CreateMarkScoresheetInput;
  entryId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
};


export type MutationCreateTallyScoresheetArgs = {
  data: CreateTallyScoresheetInput;
  entryId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
};


export type MutationCreateTeamArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateTeamInput;
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type MutationDeleteDeviceStreamShareArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationDeleteJudgeAssignmentArgs = {
  judgeAssignmentId: Scalars['ID']['input'];
};


export type MutationDeleteParticipantArgs = {
  participantId: Scalars['ID']['input'];
};


export type MutationFillMarkScoresheetArgs = {
  completedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  marks?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  openedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  programVersion?: InputMaybe<Scalars['String']['input']>;
  scoresheetId: Scalars['ID']['input'];
};


export type MutationFillTallyScoresheetArgs = {
  programVersion?: InputMaybe<Scalars['String']['input']>;
  scoresheetId: Scalars['ID']['input'];
  tally: Scalars['JSONObject']['input'];
};


export type MutationRegisterDeviceArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterUserArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReleaseRankedResultVersionArgs = {
  resultId: Scalars['ID']['input'];
};


export type MutationRemoveGroupAdminArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveGroupViewerArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationReorderEntryArgs = {
  entryId: Scalars['ID']['input'];
  heat?: InputMaybe<Scalars['Int']['input']>;
  pool?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationRequestDeviceStreamShareArgs = {
  deviceId: Scalars['ID']['input'];
};


export type MutationSetCurrentHeatArgs = {
  groupId: Scalars['ID']['input'];
  heat: Scalars['Int']['input'];
};


export type MutationSetJudgeDeviceArgs = {
  deviceId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
};


export type MutationSetPagePrintConfigArgs = {
  categoryId: Scalars['ID']['input'];
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  data: SetPagePrintConfigInput;
};


export type MutationSetRankedResultVersionArgs = {
  name: Scalars['String']['input'];
  resultId: Scalars['ID']['input'];
  type: ResultVersionType;
};


export type MutationSetScoresheetExclusionArgs = {
  exclude: Scalars['Boolean']['input'];
  scoresheetId: Scalars['ID']['input'];
};


export type MutationSetScoresheetOptionsArgs = {
  options: Scalars['JSONObject']['input'];
  scoresheetId: Scalars['ID']['input'];
};


export type MutationToggleEntryLockArgs = {
  didNotSkip?: InputMaybe<Scalars['Boolean']['input']>;
  entryId: Scalars['ID']['input'];
  lock: Scalars['Boolean']['input'];
};


export type MutationToggleGroupCompleteArgs = {
  completed: Scalars['Boolean']['input'];
  groupId: Scalars['ID']['input'];
};


export type MutationUnsetJudgeDeviceArgs = {
  judgeId: Scalars['ID']['input'];
};


export type MutationUpdateAthleteArgs = {
  data: UpdateAthleteInput;
  participantId: Scalars['ID']['input'];
};


export type MutationUpdateCategoryArgs = {
  categoryId: Scalars['ID']['input'];
  data: UpdateCategoryInput;
};


export type MutationUpdateDeviceStatusArgs = {
  batteryStatus: BatteryStatusInput;
};


export type MutationUpdateGroupArgs = {
  data: UpdateGroupInput;
  groupId: Scalars['ID']['input'];
};


export type MutationUpdateJudgeArgs = {
  data: UpdateJudgeInput;
  judgeId: Scalars['ID']['input'];
};


export type MutationUpdateJudgeAssignmentArgs = {
  data: UpdateJudgeAssignmentInput;
  judgeAssignmentId: Scalars['ID']['input'];
};


export type MutationUpdateTeamArgs = {
  data: UpdateTeamInput;
  participantId: Scalars['ID']['input'];
};


export type MutationUpdateUserArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PagePrintConfig = {
  __typename?: 'PagePrintConfig';
  competitionEventId?: Maybe<Scalars['CompetitionEventLookupCode']['output']>;
  exclude?: Maybe<Scalars['Boolean']['output']>;
  zoom?: Maybe<Scalars['Float']['output']>;
};

export type Participant = Athlete | Team;

export type Query = {
  __typename?: 'Query';
  group?: Maybe<Group>;
  groups: Array<Group>;
  me?: Maybe<Actor>;
};


export type QueryGroupArgs = {
  groupId: Scalars['ID']['input'];
};


export type QueryGroupsArgs = {
  includeCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RankedResult = {
  __typename?: 'RankedResult';
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  id: Scalars['ID']['output'];
  maxEntryLockedAt: Scalars['Timestamp']['output'];
  results: Array<Scalars['JSONObject']['output']>;
  versionName?: Maybe<Scalars['String']['output']>;
  versionType: ResultVersionType;
};

export enum ResultVersionType {
  Private = 'Private',
  Public = 'Public',
  Temporary = 'Temporary'
}

export enum ResultVisibilityLevel {
  Live = 'Live',
  Private = 'Private',
  PublicVersions = 'PublicVersions'
}

export type Scoresheet = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  createdAt: Scalars['Timestamp']['output'];
  /** @deprecated use excludedAt */
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  entry: Entry;
  excludedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  options?: Maybe<Scalars['JSONObject']['output']>;
  rulesId: Scalars['String']['output'];
  submitterProgramVersion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type SetPagePrintConfigInput = {
  exclude?: InputMaybe<Scalars['Boolean']['input']>;
  zoom?: InputMaybe<Scalars['Float']['input']>;
};

export type StreamMarkEvent = {
  __typename?: 'StreamMarkEvent';
  mark: Scalars['JSONObject']['output'];
  scoresheet: Scoresheet;
  sequence: Scalars['Int']['output'];
  tally: Scalars['JSONObject']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  deviceStreamMarkAdded: DeviceStreamMarkEvent;
  heatChanged: Scalars['Int']['output'];
  scoresheetChanged: Scalars['ID']['output'];
  streamMarkAdded: StreamMarkEvent;
};


export type SubscriptionDeviceStreamMarkAddedArgs = {
  deviceIds: Array<Scalars['ID']['input']>;
};


export type SubscriptionHeatChangedArgs = {
  groupId: Scalars['ID']['input'];
};


export type SubscriptionScoresheetChangedArgs = {
  entryIds: Array<Scalars['ID']['input']>;
};


export type SubscriptionStreamMarkAddedArgs = {
  scoresheetIds: Array<Scalars['ID']['input']>;
};

export type TallyScoresheet = Scoresheet & {
  __typename?: 'TallyScoresheet';
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  entry: Entry;
  excludedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  options?: Maybe<Scalars['JSONObject']['output']>;
  rulesId: Scalars['String']['output'];
  submitterProgramVersion?: Maybe<Scalars['String']['output']>;
  tally?: Maybe<Scalars['JSONObject']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type Team = {
  __typename?: 'Team';
  category: Category;
  club?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  members: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type UpdateAthleteInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryInput = {
  competitionEventIds?: InputMaybe<Array<Scalars['CompetitionEventLookupCode']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  name: Scalars['String']['input'];
  resultVisibility?: InputMaybe<ResultVisibilityLevel>;
};

export type UpdateJudgeAssignmentInput = {
  options?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type UpdateJudgeInput = {
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  streamShares: Array<DeviceStreamShare>;
};

export type DeviceStreamShareFragment = { __typename?: 'DeviceStreamShare', id: string, status: DeviceStreamShareStatus, expiresAt: number, user: { __typename?: 'User', id: string, name?: string | null } };

export type EntryFragment = { __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, category: { __typename?: 'Category', id: string, name: string, rulesId: string }, participant: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> } };

export type JudgeFragment = { __typename?: 'Judge', id: string, name: string };

export type AthleteFragment = { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null };

export type TeamFragment = { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> };

export type ScoresheetBaseFragment_MarkScoresheet = { __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, marks: Array<{ [prop: string]: any }>, judge: { __typename?: 'Judge', id: string, name: string }, entry: { __typename?: 'Entry', heat?: number | null, pool?: number | null } };

export type ScoresheetBaseFragment_TallyScoresheet = { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, tally?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } };

export type ScoresheetBaseFragment = ScoresheetBaseFragment_MarkScoresheet | ScoresheetBaseFragment_TallyScoresheet;

export type MarkScoresheetFragment = { __typename?: 'MarkScoresheet', completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, marks: Array<{ [prop: string]: any }>, judge: { __typename?: 'Judge', id: string, name: string }, entry: { __typename?: 'Entry', heat?: number | null, pool?: number | null } };

export type TallyScoresheetFragment = { __typename?: 'TallyScoresheet', tally?: { [prop: string]: any } | null };

export type AddDeviceStreamMarkMutationVariables = Exact<{
  mark: Scalars['JSONObject']['input'];
  tally: Scalars['JSONObject']['input'];
  info: DeviceStreamJudgeInfoInput;
}>;


export type AddDeviceStreamMarkMutation = { __typename?: 'Mutation', addDeviceStreamMark: { __typename?: 'DeviceStreamMarkEvent', sequence: number } };

export type AddStreamMarkMutationVariables = Exact<{
  scoresheetId: Scalars['ID']['input'];
  mark: Scalars['JSONObject']['input'];
  tally: Scalars['JSONObject']['input'];
}>;


export type AddStreamMarkMutation = { __typename?: 'Mutation', addStreamMark: { __typename?: 'StreamMarkEvent', sequence: number } };

export type CreateDeviceStreamShareMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type CreateDeviceStreamShareMutation = { __typename?: 'Mutation', createDeviceStreamShare: { __typename?: 'DeviceStreamShare', id: string, status: DeviceStreamShareStatus, expiresAt: number, user: { __typename?: 'User', id: string, name?: string | null } } };

export type CreateMarkScoresheetMutationVariables = Exact<{
  entryId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
  data: CreateMarkScoresheetInput;
}>;


export type CreateMarkScoresheetMutation = { __typename?: 'Mutation', createMarkScoresheet: { __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, marks: Array<{ [prop: string]: any }>, judge: { __typename?: 'Judge', id: string, name: string }, entry: { __typename?: 'Entry', heat?: number | null, pool?: number | null } } };

export type DeleteDeviceStreamShareMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type DeleteDeviceStreamShareMutation = { __typename?: 'Mutation', deleteDeviceStreamShare: { __typename?: 'DeviceStreamShare', id: string, status: DeviceStreamShareStatus, expiresAt: number, user: { __typename?: 'User', id: string, name?: string | null } } };

export type OpenScoresheetMutationVariables = Exact<{
  scoresheetId: Scalars['ID']['input'];
  openedAt: Scalars['Timestamp']['input'];
}>;


export type OpenScoresheetMutation = { __typename?: 'Mutation', fillMarkScoresheet: { __typename?: 'MarkScoresheet', id: string, openedAt?: Array<number> | null } };

export type RegisterDeviceMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterDeviceMutation = { __typename?: 'Mutation', registerDevice: string };

export type SaveScoresheetMutationVariables = Exact<{
  scoresheetId: Scalars['ID']['input'];
  completedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  marks: Array<Scalars['JSONObject']['input']> | Scalars['JSONObject']['input'];
  programVersion?: InputMaybe<Scalars['String']['input']>;
}>;


export type SaveScoresheetMutation = { __typename?: 'Mutation', fillMarkScoresheet: { __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, marks: Array<{ [prop: string]: any }>, judge: { __typename?: 'Judge', id: string, name: string }, entry: { __typename?: 'Entry', heat?: number | null, pool?: number | null } } };

export type UpdateBatteryMutationVariables = Exact<{
  batteryStatus: BatteryStatusInput;
}>;


export type UpdateBatteryMutation = { __typename?: 'Mutation', updateDeviceStatus: { __typename?: 'Device', id: string, battery?: { __typename?: 'BatteryStatus', automatic: boolean, charging?: boolean | null, batteryLevel: number, updatedAt: number } | null } };

export type DeviceStreamSharesQueryVariables = Exact<{ [key: string]: never; }>;


export type DeviceStreamSharesQuery = { __typename?: 'Query', me?: { __typename?: 'Device', id: string, streamShares: Array<{ __typename?: 'DeviceStreamShare', id: string, status: DeviceStreamShareStatus, expiresAt: number, user: { __typename?: 'User', id: string, name?: string | null } }> } | { __typename?: 'User', id: string } | null };

export type GroupScoresheetQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  entryId: Scalars['ID']['input'];
  scoresheetId: Scalars['ID']['input'];
}>;


export type GroupScoresheetQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, entry?: { __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, scoresheet?: { __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, marks: Array<{ [prop: string]: any }>, judge: { __typename?: 'Judge', id: string, name: string }, entry: { __typename?: 'Entry', heat?: number | null, pool?: number | null } } | { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, tally?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } } | null, category: { __typename?: 'Category', id: string, name: string, rulesId: string }, participant: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> } } | null } | null };

export type GroupScoresheetsQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;


export type GroupScoresheetsQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, name: string, currentHeat?: number | null, deviceJudge: { __typename?: 'Judge', id: string, name: string, assignments: Array<{ __typename?: 'JudgeAssignment', id: string, competitionEventId: any, judgeType: string, category: { __typename?: 'Category', id: string } }> }, entries: Array<{ __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, scoresheets: Array<{ __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, marks: Array<{ [prop: string]: any }>, judge: { __typename?: 'Judge', id: string, name: string }, entry: { __typename?: 'Entry', heat?: number | null, pool?: number | null } } | { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, tally?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } }>, category: { __typename?: 'Category', id: string, name: string, rulesId: string }, participant: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> } }> } | null };

export type GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, name: string, createdAt: number, completedAt?: number | null }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'Device', id: string, battery?: { __typename?: 'BatteryStatus', batteryLevel: number, updatedAt: number } | null } | { __typename?: 'User', id: string } | null };

export const DeviceStreamShareFragmentDoc = gql`
    fragment DeviceStreamShareFragment on DeviceStreamShare {
  id
  user {
    id
    name
  }
  status
  expiresAt
}
    `;
export const AthleteFragmentDoc = gql`
    fragment AthleteFragment on Athlete {
  id
  name
  club
  country
  ijruId
}
    `;
export const TeamFragmentDoc = gql`
    fragment TeamFragment on Team {
  id
  name
  club
  country
  members
}
    `;
export const EntryFragmentDoc = gql`
    fragment EntryFragment on Entry {
  id
  category {
    id
    name
    rulesId
  }
  participant {
    ...AthleteFragment
    ...TeamFragment
  }
  competitionEventId
  didNotSkipAt
  lockedAt
  heat
  pool
}
    ${AthleteFragmentDoc}
${TeamFragmentDoc}`;
export const JudgeFragmentDoc = gql`
    fragment JudgeFragment on Judge {
  id
  name
}
    `;
export const MarkScoresheetFragmentDoc = gql`
    fragment MarkScoresheetFragment on MarkScoresheet {
  completedAt
  submittedAt
  openedAt
  judge {
    id
    name
  }
  entry {
    heat
    pool
  }
  marks
}
    `;
export const TallyScoresheetFragmentDoc = gql`
    fragment TallyScoresheetFragment on TallyScoresheet {
  tally
}
    `;
export const ScoresheetBaseFragmentDoc = gql`
    fragment ScoresheetBaseFragment on Scoresheet {
  id
  judge {
    id
  }
  rulesId
  judgeType
  competitionEventId
  createdAt
  updatedAt
  excludedAt
  options
  ...MarkScoresheetFragment
  ...TallyScoresheetFragment
}
    ${MarkScoresheetFragmentDoc}
${TallyScoresheetFragmentDoc}`;
export const AddDeviceStreamMarkDocument = gql`
    mutation AddDeviceStreamMark($mark: JSONObject!, $tally: JSONObject!, $info: DeviceStreamJudgeInfoInput!) {
  addDeviceStreamMark(mark: $mark, tally: $tally, info: $info) {
    sequence
  }
}
    `;

/**
 * __useAddDeviceStreamMarkMutation__
 *
 * To run a mutation, you first call `useAddDeviceStreamMarkMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceStreamMarkMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddDeviceStreamMarkMutation({
 *   variables: {
 *     mark: // value for 'mark'
 *     tally: // value for 'tally'
 *     info: // value for 'info'
 *   },
 * });
 */
export function useAddDeviceStreamMarkMutation(options: VueApolloComposable.UseMutationOptions<AddDeviceStreamMarkMutation, AddDeviceStreamMarkMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddDeviceStreamMarkMutation, AddDeviceStreamMarkMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<AddDeviceStreamMarkMutation, AddDeviceStreamMarkMutationVariables>(AddDeviceStreamMarkDocument, options);
}
export type AddDeviceStreamMarkMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddDeviceStreamMarkMutation, AddDeviceStreamMarkMutationVariables>;
export const AddStreamMarkDocument = gql`
    mutation AddStreamMark($scoresheetId: ID!, $mark: JSONObject!, $tally: JSONObject!) {
  addStreamMark(scoresheetId: $scoresheetId, mark: $mark, tally: $tally) {
    sequence
  }
}
    `;

/**
 * __useAddStreamMarkMutation__
 *
 * To run a mutation, you first call `useAddStreamMarkMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddStreamMarkMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddStreamMarkMutation({
 *   variables: {
 *     scoresheetId: // value for 'scoresheetId'
 *     mark: // value for 'mark'
 *     tally: // value for 'tally'
 *   },
 * });
 */
export function useAddStreamMarkMutation(options: VueApolloComposable.UseMutationOptions<AddStreamMarkMutation, AddStreamMarkMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<AddStreamMarkMutation, AddStreamMarkMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<AddStreamMarkMutation, AddStreamMarkMutationVariables>(AddStreamMarkDocument, options);
}
export type AddStreamMarkMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<AddStreamMarkMutation, AddStreamMarkMutationVariables>;
export const CreateDeviceStreamShareDocument = gql`
    mutation CreateDeviceStreamShare($userId: ID!) {
  createDeviceStreamShare(userId: $userId) {
    ...DeviceStreamShareFragment
  }
}
    ${DeviceStreamShareFragmentDoc}`;

/**
 * __useCreateDeviceStreamShareMutation__
 *
 * To run a mutation, you first call `useCreateDeviceStreamShareMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceStreamShareMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateDeviceStreamShareMutation({
 *   variables: {
 *     userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateDeviceStreamShareMutation(options: VueApolloComposable.UseMutationOptions<CreateDeviceStreamShareMutation, CreateDeviceStreamShareMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateDeviceStreamShareMutation, CreateDeviceStreamShareMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateDeviceStreamShareMutation, CreateDeviceStreamShareMutationVariables>(CreateDeviceStreamShareDocument, options);
}
export type CreateDeviceStreamShareMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateDeviceStreamShareMutation, CreateDeviceStreamShareMutationVariables>;
export const CreateMarkScoresheetDocument = gql`
    mutation CreateMarkScoresheet($entryId: ID!, $judgeId: ID!, $data: CreateMarkScoresheetInput!) {
  createMarkScoresheet(entryId: $entryId, judgeId: $judgeId, data: $data) {
    ...ScoresheetBaseFragment
  }
}
    ${ScoresheetBaseFragmentDoc}`;

/**
 * __useCreateMarkScoresheetMutation__
 *
 * To run a mutation, you first call `useCreateMarkScoresheetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarkScoresheetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateMarkScoresheetMutation({
 *   variables: {
 *     entryId: // value for 'entryId'
 *     judgeId: // value for 'judgeId'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useCreateMarkScoresheetMutation(options: VueApolloComposable.UseMutationOptions<CreateMarkScoresheetMutation, CreateMarkScoresheetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateMarkScoresheetMutation, CreateMarkScoresheetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateMarkScoresheetMutation, CreateMarkScoresheetMutationVariables>(CreateMarkScoresheetDocument, options);
}
export type CreateMarkScoresheetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateMarkScoresheetMutation, CreateMarkScoresheetMutationVariables>;
export const DeleteDeviceStreamShareDocument = gql`
    mutation DeleteDeviceStreamShare($userId: ID!) {
  deleteDeviceStreamShare(userId: $userId) {
    ...DeviceStreamShareFragment
  }
}
    ${DeviceStreamShareFragmentDoc}`;

/**
 * __useDeleteDeviceStreamShareMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceStreamShareMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceStreamShareMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteDeviceStreamShareMutation({
 *   variables: {
 *     userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteDeviceStreamShareMutation(options: VueApolloComposable.UseMutationOptions<DeleteDeviceStreamShareMutation, DeleteDeviceStreamShareMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteDeviceStreamShareMutation, DeleteDeviceStreamShareMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteDeviceStreamShareMutation, DeleteDeviceStreamShareMutationVariables>(DeleteDeviceStreamShareDocument, options);
}
export type DeleteDeviceStreamShareMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteDeviceStreamShareMutation, DeleteDeviceStreamShareMutationVariables>;
export const OpenScoresheetDocument = gql`
    mutation OpenScoresheet($scoresheetId: ID!, $openedAt: Timestamp!) {
  fillMarkScoresheet(scoresheetId: $scoresheetId, openedAt: $openedAt) {
    id
    openedAt
  }
}
    `;

/**
 * __useOpenScoresheetMutation__
 *
 * To run a mutation, you first call `useOpenScoresheetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useOpenScoresheetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useOpenScoresheetMutation({
 *   variables: {
 *     scoresheetId: // value for 'scoresheetId'
 *     openedAt: // value for 'openedAt'
 *   },
 * });
 */
export function useOpenScoresheetMutation(options: VueApolloComposable.UseMutationOptions<OpenScoresheetMutation, OpenScoresheetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<OpenScoresheetMutation, OpenScoresheetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<OpenScoresheetMutation, OpenScoresheetMutationVariables>(OpenScoresheetDocument, options);
}
export type OpenScoresheetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<OpenScoresheetMutation, OpenScoresheetMutationVariables>;
export const RegisterDeviceDocument = gql`
    mutation RegisterDevice($name: String) {
  registerDevice(name: $name)
}
    `;

/**
 * __useRegisterDeviceMutation__
 *
 * To run a mutation, you first call `useRegisterDeviceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDeviceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRegisterDeviceMutation({
 *   variables: {
 *     name: // value for 'name'
 *   },
 * });
 */
export function useRegisterDeviceMutation(options: VueApolloComposable.UseMutationOptions<RegisterDeviceMutation, RegisterDeviceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RegisterDeviceMutation, RegisterDeviceMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RegisterDeviceMutation, RegisterDeviceMutationVariables>(RegisterDeviceDocument, options);
}
export type RegisterDeviceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RegisterDeviceMutation, RegisterDeviceMutationVariables>;
export const SaveScoresheetDocument = gql`
    mutation SaveScoresheet($scoresheetId: ID!, $completedAt: Timestamp, $marks: [JSONObject!]!, $programVersion: String) {
  fillMarkScoresheet(
    scoresheetId: $scoresheetId
    completedAt: $completedAt
    marks: $marks
    programVersion: $programVersion
  ) {
    ...ScoresheetBaseFragment
  }
}
    ${ScoresheetBaseFragmentDoc}`;

/**
 * __useSaveScoresheetMutation__
 *
 * To run a mutation, you first call `useSaveScoresheetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveScoresheetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveScoresheetMutation({
 *   variables: {
 *     scoresheetId: // value for 'scoresheetId'
 *     completedAt: // value for 'completedAt'
 *     marks: // value for 'marks'
 *     programVersion: // value for 'programVersion'
 *   },
 * });
 */
export function useSaveScoresheetMutation(options: VueApolloComposable.UseMutationOptions<SaveScoresheetMutation, SaveScoresheetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SaveScoresheetMutation, SaveScoresheetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SaveScoresheetMutation, SaveScoresheetMutationVariables>(SaveScoresheetDocument, options);
}
export type SaveScoresheetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SaveScoresheetMutation, SaveScoresheetMutationVariables>;
export const UpdateBatteryDocument = gql`
    mutation UpdateBattery($batteryStatus: BatteryStatusInput!) {
  updateDeviceStatus(batteryStatus: $batteryStatus) {
    id
    battery {
      automatic
      charging
      batteryLevel
      updatedAt
    }
  }
}
    `;

/**
 * __useUpdateBatteryMutation__
 *
 * To run a mutation, you first call `useUpdateBatteryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBatteryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateBatteryMutation({
 *   variables: {
 *     batteryStatus: // value for 'batteryStatus'
 *   },
 * });
 */
export function useUpdateBatteryMutation(options: VueApolloComposable.UseMutationOptions<UpdateBatteryMutation, UpdateBatteryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateBatteryMutation, UpdateBatteryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateBatteryMutation, UpdateBatteryMutationVariables>(UpdateBatteryDocument, options);
}
export type UpdateBatteryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateBatteryMutation, UpdateBatteryMutationVariables>;
export const DeviceStreamSharesDocument = gql`
    query DeviceStreamShares {
  me {
    ... on Device {
      id
      streamShares {
        ...DeviceStreamShareFragment
      }
    }
    ... on User {
      id
    }
  }
}
    ${DeviceStreamShareFragmentDoc}`;

/**
 * __useDeviceStreamSharesQuery__
 *
 * To run a query within a Vue component, call `useDeviceStreamSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceStreamSharesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDeviceStreamSharesQuery();
 */
export function useDeviceStreamSharesQuery(options: VueApolloComposable.UseQueryOptions<DeviceStreamSharesQuery, DeviceStreamSharesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DeviceStreamSharesQuery, DeviceStreamSharesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DeviceStreamSharesQuery, DeviceStreamSharesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DeviceStreamSharesQuery, DeviceStreamSharesQueryVariables>(DeviceStreamSharesDocument, {}, options);
}
export function useDeviceStreamSharesLazyQuery(options: VueApolloComposable.UseQueryOptions<DeviceStreamSharesQuery, DeviceStreamSharesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DeviceStreamSharesQuery, DeviceStreamSharesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DeviceStreamSharesQuery, DeviceStreamSharesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<DeviceStreamSharesQuery, DeviceStreamSharesQueryVariables>(DeviceStreamSharesDocument, {}, options);
}
export type DeviceStreamSharesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DeviceStreamSharesQuery, DeviceStreamSharesQueryVariables>;
export const GroupScoresheetDocument = gql`
    query GroupScoresheet($groupId: ID!, $entryId: ID!, $scoresheetId: ID!) {
  group(groupId: $groupId) {
    id
    entry(entryId: $entryId) {
      ...EntryFragment
      scoresheet(scoresheetId: $scoresheetId) {
        ...ScoresheetBaseFragment
      }
    }
  }
}
    ${EntryFragmentDoc}
${ScoresheetBaseFragmentDoc}`;

/**
 * __useGroupScoresheetQuery__
 *
 * To run a query within a Vue component, call `useGroupScoresheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupScoresheetQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGroupScoresheetQuery({
 *   groupId: // value for 'groupId'
 *   entryId: // value for 'entryId'
 *   scoresheetId: // value for 'scoresheetId'
 * });
 */
export function useGroupScoresheetQuery(variables: GroupScoresheetQueryVariables | VueCompositionApi.Ref<GroupScoresheetQueryVariables> | ReactiveFunction<GroupScoresheetQueryVariables>, options: VueApolloComposable.UseQueryOptions<GroupScoresheetQuery, GroupScoresheetQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupScoresheetQuery, GroupScoresheetQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupScoresheetQuery, GroupScoresheetQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GroupScoresheetQuery, GroupScoresheetQueryVariables>(GroupScoresheetDocument, variables, options);
}
export function useGroupScoresheetLazyQuery(variables?: GroupScoresheetQueryVariables | VueCompositionApi.Ref<GroupScoresheetQueryVariables> | ReactiveFunction<GroupScoresheetQueryVariables>, options: VueApolloComposable.UseQueryOptions<GroupScoresheetQuery, GroupScoresheetQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupScoresheetQuery, GroupScoresheetQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupScoresheetQuery, GroupScoresheetQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GroupScoresheetQuery, GroupScoresheetQueryVariables>(GroupScoresheetDocument, variables, options);
}
export type GroupScoresheetQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GroupScoresheetQuery, GroupScoresheetQueryVariables>;
export const GroupScoresheetsDocument = gql`
    query GroupScoresheets($groupId: ID!) {
  group(groupId: $groupId) {
    id
    name
    currentHeat
    deviceJudge {
      ...JudgeFragment
      assignments {
        id
        competitionEventId
        judgeType
        category {
          id
        }
      }
    }
    entries {
      ...EntryFragment
      scoresheets {
        ...ScoresheetBaseFragment
      }
    }
  }
}
    ${JudgeFragmentDoc}
${EntryFragmentDoc}
${ScoresheetBaseFragmentDoc}`;

/**
 * __useGroupScoresheetsQuery__
 *
 * To run a query within a Vue component, call `useGroupScoresheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupScoresheetsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGroupScoresheetsQuery({
 *   groupId: // value for 'groupId'
 * });
 */
export function useGroupScoresheetsQuery(variables: GroupScoresheetsQueryVariables | VueCompositionApi.Ref<GroupScoresheetsQueryVariables> | ReactiveFunction<GroupScoresheetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GroupScoresheetsQuery, GroupScoresheetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupScoresheetsQuery, GroupScoresheetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupScoresheetsQuery, GroupScoresheetsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GroupScoresheetsQuery, GroupScoresheetsQueryVariables>(GroupScoresheetsDocument, variables, options);
}
export function useGroupScoresheetsLazyQuery(variables?: GroupScoresheetsQueryVariables | VueCompositionApi.Ref<GroupScoresheetsQueryVariables> | ReactiveFunction<GroupScoresheetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GroupScoresheetsQuery, GroupScoresheetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupScoresheetsQuery, GroupScoresheetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupScoresheetsQuery, GroupScoresheetsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GroupScoresheetsQuery, GroupScoresheetsQueryVariables>(GroupScoresheetsDocument, variables, options);
}
export type GroupScoresheetsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GroupScoresheetsQuery, GroupScoresheetsQueryVariables>;
export const GroupsDocument = gql`
    query Groups {
  groups {
    id
    name
    createdAt
    completedAt
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a Vue component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGroupsQuery();
 */
export function useGroupsQuery(options: VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, {}, options);
}
export function useGroupsLazyQuery(options: VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, {}, options);
}
export type GroupsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GroupsQuery, GroupsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ... on Device {
      id
      battery {
        batteryLevel
        updatedAt
      }
    }
    ... on User {
      id
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export function useMeLazyQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;